<script>
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'retailer-gift-confirmation-modal',
  components: {
    BaseButton,
    BaseIcon,
    BaseMdl,
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    recipient: {
      type: Object,
      default: null,
    },
    physical: {
      type: Boolean,
      default: false,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
  },
  methods: {
    confirm() {
      this.onConfirm?.()
      modal.hide()
    },
    cancel() {
      this.onCancel?.()
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl width="xs">
    <div class="justfy-center flex flex-col text-center">
      <div class="mx-auto text-primary">
        <base-icon svg="gift" size="4xl" />
      </div>

      <h4 class="my-5 text-2xl">Gift Confirmation</h4>

      <template v-if="physical">
        <div class="py-5">
          <div class="font-bold">Send to</div>
          <div>{{ recipient.name }}</div>
          <div>{{ recipient.email }}</div>
          <div>{{ recipient.mobile }}</div>
        </div>
        <hr />
        <div class="py-5">
          <div class="font-bold">Recipient Address</div>
          <div>
            {{ recipient.streetAddress }} {{ recipient.suburb }}
            {{ recipient.state.toUpperCase() }} {{ recipient.postcode }}
          </div>
        </div>
      </template>

      <template v-else>
        <div class="py-5">
          <div v-for="label in labels" :key="label" class="font-bold">
            {{ label }}
          </div>
        </div>
        <div class="py-5">
          <div class="font-bold">Name</div>
          <div>{{ recipient.name }}</div>
          <div>{{ recipient.email }}</div>
        </div>
      </template>

      <div v-if="recipient.message" class="py-5">
        <div class="font-bold">Message</div>
        <div>
          {{ recipient.message }}
        </div>
      </div>
    </div>

    <div class="space-y-5 pt-5">
      <base-button full-width @click="confirm">Confirm</base-button>
      <base-button look="outlined" full-width @click="cancel">
        Cancel
      </base-button>
    </div>
  </base-mdl>
</template>
